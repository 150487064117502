/// <reference path="../../../node_modules/@types/gapi/index.d.ts" />
/// <reference path="../../../node_modules/@types/gapi.auth2/index.d.ts" />
import { Injectable, NgZone } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleAuthService {
  public auth2: gapi.auth2.GoogleAuth;
  public isLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private zone: NgZone) { }

  signIn(): Observable<gapi.auth2.GoogleUser> {
    const result = new Subject<gapi.auth2.GoogleUser>();
    this.auth2.signIn({
      scope: 'profile email'
    }).then((user) => {
      this.zone.run(() => {
        result.next(user);
        result.complete();
      });
    }).catch((err) => {
      this.zone.run(() => {
        result.error(err);
      });
    });
    return result;
  }

  signOut(): Observable<boolean> {
    const result = new Subject<boolean>();
    this.auth2.signOut().then(() => {
      this.zone.run(() => {
        result.next(true);
      });
    }).catch((err) => {
      this.zone.run(() => {
        result.error(err);
      });
    });
    return result;
  }

  init(): void {
    gapi.load('auth2', () => {
      gapi.auth2.init({
        client_id: environment.google_oauth_client_id,
        hosted_domain: 'icrimefighter.com',
        fetch_basic_profile: true
      }).then((auth) => {
        this.zone.run(() => {
          this.auth2 = auth;
          this.isLoaded$.next(true);
        });
      });
    });
  }
}
