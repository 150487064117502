import { Injectable } from '@angular/core';
import { orderBy, each } from 'lodash';
import { HttpService } from '../http.service';
import { APIService } from '../../app.constants';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/table';
import { Activity } from '../../models/activity';
import { Moment } from 'moment';
import { BehaviorSubject, Observable, merge } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import moment from 'moment';

@Injectable()
export class ActivityService {
  private baseUrl = APIService.Activities;
  private _activities: BehaviorSubject<Activity[]>;
  public readonly activities: Observable<Activity[]>;
  private dataStore: {
    activities: Activity[]
  };

  constructor(private httpService: HttpService) {
    this.dataStore = { activities: [] };
    this._activities = <BehaviorSubject<Activity[]>>new BehaviorSubject([]);
    this.activities = this._activities.asObservable();
  }

  get data(): Activity[] { return this._activities.value; }

  loadAll(): any {
    return this.httpService.get(this.baseUrl).pipe(map(response => {
      this.dataStore.activities = response['data'];
      this._activities.next(Object.assign({}, this.dataStore).activities);
    })).subscribe();
  }

  filter(event: string, src: string, query?: any, from?: Date | Moment, to?: Date | Moment, filter?: string, userFilter?: string) {
    const params: any = { event, src };

    if (filter) {
      if (filter === 'all_cases') {
        params.filter = filter;
      } else { // for userfilter in single case activities
        params.userfilter = filter;
      }
    }

    if (userFilter) {
      params.userfilter = userFilter;
    }

    if (from) { params.from = from.toJSON() }
    if (to) { params.to = to.toJSON() }
    if (query) {
      each(query, (v, k) => {
        params[`query[${k}]`] = v;
      });
    }

    return this.httpService.get(this.baseUrl, { params }).pipe(map(response => {
      this.dataStore.activities = response['data'];
      this._activities.next(Object.assign({}, this.dataStore).activities);
    })).subscribe();
  }

  toDataSource(paginator: MatPaginator, sort: MatSort): ActivityDataSource {
    return new ActivityDataSource(this, paginator, sort);
  }

  filterAndExportEvents(obj: { event: string, src: string, query?: any, from?: Date | Moment, to?: Date | Moment, filter?: string, userFilter?: string }) {
    const { event, src, query, from, to, filter, userFilter } = obj;
    const params: any = { event, src };
    if (filter) {
      if (filter === 'all_cases') {
        params.filter = filter;
      } else { // for userfilter in single case activities
        params.userfilter = filter;
      }
    }

    if (userFilter) {
      params.userfilter = userFilter;
    }

    if (from) { params.from = from.toJSON() }
    if (to) { params.to = to.toJSON() }
    if (query) {
      each(query, (v, k) => {
        params[`query[${k}]`] = v;
      });
    }

    params.utcOffset = moment().utcOffset();
    const tzArrray = new Date().toString().match(/\(([A-Za-z\s].*)\)/);
    params.timeZone = tzArrray[1] ? tzArrray[1] : '';

    return this.httpService.get(`${this.baseUrl}/pdf`, { params })
  }

  getEventsPdfBlob(fileUrl: string) {
    return this.httpService.getRaw(fileUrl, { responseType: 'blob' })
  }
}

export class ActivityDataSource extends DataSource<any> {
  constructor(private _service: ActivityService,
    private _paginator: MatPaginator,
    private _sort: MatSort) {
    super();
  }

  connect(): Observable<Activity[]> {
    const displayDataChanges = [
      this._service.activities,
      this._paginator.page,
      this._sort.sortChange
    ];

    // If the user changes the sort order, reset back to the first page.
    this._sort.sortChange.subscribe(() => {
      this._paginator.pageIndex = 0;
    });

    return merge(...displayDataChanges).pipe(startWith(null), map(() => {
      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      return this.getSortedData().splice(startIndex, this._paginator.pageSize);
    }));
  }
  disconnect() { }

  private getSortedData(): Activity[] {
    const data = this._service.data.slice();
    if (!this._sort.active || this._sort.direction === '') { return data; }

    switch (this._sort.active) {
      case 'event_name': return orderBy(data, ['event_name'], this._sort.direction);
      case 'event_info': return orderBy(data, ['data.status'], this._sort.direction);
      case 'created_at': return orderBy(data, ['created_at'], this._sort.direction);
    }
  }
}
