import { appVersion } from 'app/app.version';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { UserService } from '../../shared/api.services/user.service';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throwError } from 'rxjs';
import { catchError, tap, flatMap } from 'rxjs/operators';
import { GoogleAuthService } from '../google-auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loading = false;
  visiblePassword = false;
  now = new Date();
  version = appVersion;
  form: UntypedFormGroup;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private googleAuthService: GoogleAuthService,
    private userService: UserService) {

    this.form = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });

    this.googleAuthService.init();
  }

  get username(): AbstractControl {
    return this.form.get('username');
  }

  get password(): AbstractControl {
    return this.form.get('password');
  }

  googleAuth() {
    this.loading = true;
    this.googleAuthService.signIn().pipe(catchError((err) => {
      this.loading = false;
      console.log(err)
      return throwError(err);
    }), flatMap(auth_response => {
      return this.userService.oAuthLogin(auth_response.getAuthResponse().id_token).pipe(catchError((err) => {
        this.loading = false;
        this.snackBar.open('Not authorised', null, {
          duration: 3000
        });
        return throwError(err);
      }), tap(res => {
        return this.authService.register(res).subscribe();
      }));
    })).subscribe(res => {
      this.router.navigateByUrl(this.authService.redirectUrl || '/', {});
    });
  }

  onSubmit() {
    if (!this.form.valid) { return; }

    this.loading = true;
    this.userService.login(this.username.value, this.password.value).pipe(catchError((err) => {
      this.loading = false;
      this.snackBar.open('Invalid email or password!', null, {
        duration: 3000
      });
      return throwError(err);
    }), tap(res => {
      return this.authService.register(res).subscribe();
    })).subscribe(res => {
      this.router.navigateByUrl(this.authService.redirectUrl || '/', {});
    });
  }
}
