import { map, take } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { APIService } from '../../app.constants';
import { License } from '../../models/license';

@Injectable()
export class LicenseService {
  private baseUrl = APIService.Licenses;
  private _licenses: BehaviorSubject<License[]>;
  public readonly licenses: Observable<License[]>;
  private dataStore: {
    licenses: License[]
  };

  constructor(private httpService: HttpService) {
    this.dataStore = { licenses: [] };
    this._licenses = <BehaviorSubject<License[]>>new BehaviorSubject([]);
    this.licenses = this._licenses.asObservable();
  }

  get data(): License[] { return this._licenses.value; }

  get(id: string) {
    return this.licenses.pipe(map(licenses => licenses.find(item => item.id.toString() === id.toString())));
  }

  loadAll(agencyId: string): any {
    const params = { agency_id: agencyId };

    return this.httpService.get(this.baseUrl, { params }).pipe(map(response => {
      this.dataStore.licenses = response['data'];
      this._licenses.next(Object.assign({}, this.dataStore).licenses);
    })).subscribe();
  }

  load(id: string): any {
    return this.httpService.get(`${this.baseUrl}/${id}`).pipe(map((response: any) => {
      const license = response.data;
      const storeIndex = this.dataStore.licenses.findIndex((item) => item.id === license.id);
      if (storeIndex !== -1) {
        this.dataStore.licenses[storeIndex] = license;
      } else {
        this.dataStore.licenses.push(license);
      }
      this._licenses.next(Object.assign({}, this.dataStore).licenses);
      return license;
    }));
  }

  create(license: License) {
    return this.httpService.post(this.baseUrl, license).pipe(map(response => {
      license.id = response['data'].id;
      this.dataStore.licenses.push(license);
      this._licenses.next(Object.assign({}, this.dataStore).licenses);
      return response;
    }));
  }

  update(license: License) {
    return this.httpService.post(`${this.baseUrl}/${license.id}`, license).pipe(map(response => {
      this.dataStore.licenses.forEach((c, i) => {
        if (c.id === license.id) { this.dataStore.licenses[i] = license; }
      });

      this._licenses.next(Object.assign({}, this.dataStore).licenses);
      return response;
    }));
  }

}
