import { Injectable } from '@angular/core';
import {
  HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse,
  HttpErrorResponse, HttpResponseBase
} from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, filter } from 'rxjs/operators';
import { SessionStorage } from 'ngx-webstorage';
import { isExternalRequest } from 'app/app.util';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  @SessionStorage('isLoading', false) private loading;

  constructor(
    private router: Router,
    private authService: AuthService) { }

  private getAdditionalHeaders(url: string) {
    let headersMap = {};
    if (!isExternalRequest(url)) {
      headersMap = {
        ...headersMap,
        Authorization: this.authService.token || ''
      };
    }
    return headersMap;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loading = true;
    const additionalHeaders = this.getAdditionalHeaders(request.url);
    request = request.clone({
      setHeaders: additionalHeaders
    });

    return next.handle(request).pipe(
      filter((event) => event instanceof HttpResponseBase),
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body.refresh_access_token) {
            this.authService.setToken(event.body.refresh_access_token);
          }
          this.loading = false;
          // do stuff with response if you want
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          this.loading = false;
          if (err.status === 401) {
            this.authService.logout();
            this.router.navigateByUrl('/login');
            // return err;
          }
        }
      }),
    );
  }
}
