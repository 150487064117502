import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StaticRoutingModule} from './static-routing.module';
import {NotfoundComponent} from './notfound/notfound.component';

@NgModule({
  imports: [
    CommonModule,
    StaticRoutingModule
  ],
  declarations: [
    NotfoundComponent
  ]
})
export class StaticModule { }
