import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { APIService } from '../../app.constants';
import { Agency } from '../../models/agency';

@Injectable()
export class AgencyService {
  private baseUrl = APIService.Agencies;
  private _agencies: BehaviorSubject<Agency[]>;
  public readonly agencies: Observable<Agency[]>;
  private dataStore: {
    agencies: Agency[]
  };

  constructor(private httpService: HttpService) {
    this.dataStore = { agencies: [] };
    this._agencies = <BehaviorSubject<Agency[]>>new BehaviorSubject([]);
    this.agencies = this._agencies.asObservable();
  }

  get data(): Agency[] { return this._agencies.value; }

  get(id: string) {
    return this.agencies.pipe(map(agencies => agencies.find(item => item.id.toString() === id.toString())));
  }

  getAll(type: string): Observable<Agency[]> {
    const params = { type };
    return this.httpService.get(this.baseUrl, { params }).pipe(map(response => {
      return response['data'];
    }));
  }

  loadAll(type: string, status = 'active'): any {
    const params: any = { type };
    if (status === 'deleted') params.filter = 'deleted';
    return this.httpService.get(this.baseUrl, { params }).pipe(map(response => {
      this.dataStore.agencies = response['data'];
      this._agencies.next(Object.assign({}, this.dataStore).agencies);
    })).subscribe();
  }

  loadAllTypeAgencies() {
    const params = { type: 'all' }
    return this.httpService.get(this.baseUrl, { params }).pipe(
      map((res: any) => res.data)
    );
  }

  load(id: string): any {
    return this.httpService.get(`${this.baseUrl}/${id}`).pipe(map(response => {
      let notFound = true;

      this.dataStore.agencies.forEach((item, index) => {
        if (item.id === response['data'].id) {
          this.dataStore.agencies[index] = response['data'];
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.agencies.push(response['data']);
      }

      this._agencies.next(Object.assign({}, this.dataStore).agencies);
      return response['data'];
    }))
  }

  create(agency: Agency) {
    return this.httpService.post(this.baseUrl, agency).pipe(map(response => {
      agency.id = response['data'].id;
      this.dataStore.agencies.push(agency);
      this._agencies.next(Object.assign({}, this.dataStore).agencies);
      return response;
    }));
  }

  update(agency: Agency) {
    return this.httpService.post(`${this.baseUrl}/${agency.id}`, agency).pipe(map(response => {
      this.dataStore.agencies.forEach((c, i) => {
        if (c.id === agency.id) { this.dataStore.agencies[i] = agency; }
      });

      this._agencies.next(Object.assign({}, this.dataStore).agencies);
      return response;
    }));
  }

  remove(id: string) {
    return this.httpService.delete(`${this.baseUrl}/${id}`).pipe(map(() => {
      this.dataStore.agencies.forEach((c, i) => {
        if (c.id === id) { this.dataStore.agencies.splice(i, 1); }
      });

      this._agencies.next(Object.assign({}, this.dataStore).agencies);
    }));
  }

  restore(id: string | number) {
    return this.httpService.post(`${this.baseUrl}/${id}/restore`).pipe(map(() => {
      this.dataStore.agencies.forEach((c, i) => {
        if (c.id === id) { this.dataStore.agencies.splice(i, 1); }
      });

      this._agencies.next(Object.assign({}, this.dataStore).agencies);
    }));
  }
}
