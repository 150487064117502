export enum Status {
  ACTIVE = 1,
  DELETED = 2,
  ARCHIVED = 3,
  INACTIVE = 4,
  FAILED = 6,
  MERGED = 7,
  UPLOADING = 8,
  PROCESSING = 9,
  VERIFIED = 10,
};

export enum ApiError {
  AccountLimitReached = 'AccountLimitReached',
};

export enum UserPreferenceName {
  SharedCasesNotification = 'shared_cases',
  NewCaseNotification = 'new_case',
  NewAudioNotification = 'new_audio_file',
  NewVideoNotification = 'new_video_file',
  DownloadConfirmationNotification = 'download_confirmation',
  DownloadZipping = 'download_zip_with_folders',
};

export enum UserPreferenceDescription {
  SharedCasesNotification = 'Shared Cases',
  NewCaseNotification = 'New Case',
  NewAudioNotification = 'New Audio File',
  NewVideoNotification = 'New Video File',
  DownloadConfirmationNotification = 'Download Confirmation',
  DownloadZipping = 'Download Zip With Folders'
};

export enum SelectionState {
  NONE = 'none',
  FEW = 'few',
  PAGE = 'page',
  ALL = 'all',
};

export enum EmailProviderNames {
  Ses = 'ses',
  Postmark = 'postmark'
};

export enum EmailProviderDesc {
  Ses = 'SES',
  Postmark = 'Postmark'
};

export enum Settings {
  AppEmailer = 'emailer',
  NotificationEmailer = 'notification_emailer',
  ServerDown = 'server_down_alert'
}

export function getTitleCase(input: string): string {
  return input.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
}

export const downloadFileFromBlob = (blob: Blob, fileName: string): void => {
  // For other browsers except IE: 
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(blob);
  var link = document.createElement('a');
  link.href = data;
  link.download = fileName;
  link.click();
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 400)
}

export function isExternalRequest(url: string) { // requests that do not require session token for authorization
  const extReqUrlSet = {
    ATSCENEAWSURL: `https://atscene`
  }

  let isExtReq = false;
  for (let key in extReqUrlSet) {
    if (url.indexOf(extReqUrlSet[key]) !== -1) {
      isExtReq = true;
    }
  }

  return isExtReq;
}