import { map, take } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { each } from 'lodash';
import { HttpService } from '../http.service';
import { APIService } from '../../app.constants';
import { User } from '../../models/user';
import * as md5 from 'md5';

@Injectable()
export class UserService {
  private baseUrl = APIService.Users;
  private _users: BehaviorSubject<User[]>;
  public readonly users: Observable<User[]>;
  private dataStore: {
    users: User[]
  };

  constructor(private httpService: HttpService) {
    this.dataStore = { users: [] };
    this._users = <BehaviorSubject<User[]>>new BehaviorSubject([]);
    this.users = this._users.asObservable();
  }

  get data(): User[] { return this._users.value; }

  get(id: string) {
    return this.users.pipe(map(users => users.find(item => item.id.toString() === id.toString())));
  }

  loadAll(agencyId: string, type?: string): any {
    const params: any = { agency_id: agencyId };
    if (type) {
      params.type = type;
    }
    return this.httpService.get(this.baseUrl, { params }).pipe(map(response => {
      this.dataStore.users = response['data'];
      this._users.next(Object.assign({}, this.dataStore).users);
    }), take(1)).subscribe();
  }

  load(id: string): any {
    return this.httpService.get(`${this.baseUrl}/${id}`).pipe(map(response => {
      let notFound = true;

      this.dataStore.users.forEach((item, index) => {
        if (item.id === response['data'].id) {
          this.dataStore.users[index] = response['data'];
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.users.push(response['data']);
      }

      this._users.next(Object.assign({}, this.dataStore).users);
      return response['data'];
    }), take(1));
  }

  create(user: User) {
    return this.httpService.post(this.baseUrl, user).pipe(map(response => {
      user.id = response['data'].id;
      this.dataStore.users.push(user);
      this._users.next(Object.assign({}, this.dataStore).users);
      return response;
    }), take(1));
  }

  bulkCreate(agencyId, users: User[]) {
    const params = {
      agency_id: agencyId,
      users: users
    };
    return this.httpService.post(`${this.baseUrl}/batch`, params).pipe(map((response: any) => {
      each(response.data, user => this.dataStore.users.push(user));
      this._users.next(Object.assign({}, this.dataStore).users);
      return response;
    }), take(1));
  }

  update(user: User) {
    return this.httpService.post(`${this.baseUrl}/${user.id}/update`, user).pipe(map(response => {
      this.dataStore.users.forEach((c, i) => {
        if (c.id === user.id) { this.dataStore.users[i] = user; }
      });

      this._users.next(Object.assign({}, this.dataStore).users);
      return response;
    }), take(1));
  }

  remove(id: string) {
    return this.httpService.delete(`${this.baseUrl}/${id}`).pipe(map(response => {
      this.dataStore.users.forEach((c, i) => {
        if (c.id === id) { this.dataStore.users.splice(i, 1); }
      });

      this._users.next(Object.assign({}, this.dataStore).users);
      return response;
    }), take(1));
  }

  restore(id: string) {
    return this.httpService.post(`${this.baseUrl}/${id}/restore`).pipe(map(response => {
      this.dataStore.users.forEach((c, i) => {
        if (c.id === id) { this.dataStore.users.splice(i, 1); }
      });

      this._users.next(Object.assign({}, this.dataStore).users);
      return response;
    }));
  }

  login(username: string, password: string): Observable<any> {
    const obj = {
      username: username,
      password: md5(password),
      role: 'admin'
    };
    return this.httpService.post(`${this.baseUrl}/login`, obj);
  }

  oAuthLogin(token: string): Observable<any> {
    const obj = {
      token: token,
      role: 'admin'
    };
    return this.httpService.post(`${APIService.Auth}/oauth`, obj);
  }

  reset(id: string) {
    return this.httpService.post(`${this.baseUrl}/reset`, { id });
  }

  profile() {
    return this.httpService.get(`${this.baseUrl}/me`);
  }

  getPermissions(id: string, agencyId: string) {
    return this.httpService.get(`${this.baseUrl}/${id}/permissions?agency_id=${agencyId || ''}`);
  }

  updatePermissions(id: string, data) {
    return this.httpService.put(`${this.baseUrl}/${id}/permissions`, data);
  }

  logout(payload: any) {
    return this.httpService.post(`${this.baseUrl}/logout`, payload);
  }
}
