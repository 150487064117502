import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash'
import * as md5 from 'md5';
import jwt_decode from "jwt-decode";
import { LocalStorage } from 'ngx-webstorage';
import { User } from '../models/user';
import { UserService } from '../shared/api.services/user.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

@Injectable()
export class AuthService {
  @LocalStorage('authToken', null) private authToken;
  redirectUrl: string;
  loadedUrl: string;
  user: User = null;
  private currentExpiryTimeStamp: number;
  expirySubject: BehaviorSubject<number>;

  constructor(private userService: UserService) { }

  register(res): Observable<boolean> {
    return of(res.data).pipe(tap(data => {
      this.user = data.user;
      this.setToken(data.session_token);
      return data.session_token;
    }));
  }

  get status(): Observable<boolean> {
    if (!isEmpty(this.authToken)) {
      if (this.user !== null) {
        return of(true);
      } else {
        return this.userService.profile().pipe(map((response: any) => {
          this.user = response.data;
          this.setAuthExpiryTimer();
          return true;
        }), catchError((err) => {
          this.authToken = null;
          this.user = null;
          return of(false);
        }));
      }
    } else {
      return of(false);
    }
  }

  get hash(): string {
    return md5(this.user.id);
  }

  get token(): string {
    return this.authToken;
  }

  setToken(token) {
    this.authToken = token;
    this.setAuthExpiryTimer();
  }

  setAuthExpiryTimer(): void {
    /* 
      - set a behaviour subject to trigger the timer observable
      - to-do: create a composite observable which can dynamically pass due date value to the timer observable
      - so the timer can be auto refreshed when the session gets refreshed
    */
    const decodedToken: any = jwt_decode(this.authToken);
    this.currentExpiryTimeStamp = decodedToken.exp * 1000;
    if (this.expirySubject) this.expirySubject.next(this.currentExpiryTimeStamp);
    else this.expirySubject = new BehaviorSubject<number>(this.currentExpiryTimeStamp);
  }

  /* update existing auth user */
  updateAuthUser() {
    this.userService.profile().subscribe(
      (res: any) => {
        this.user = res.data;
      },
      (err) => {
        this.authToken = null;
        this.user = null;
      }
    );
  }

  clear() {
    this.authToken = null;
    this.user = null;
  }

  logout(): Observable<any> {
    const payload = { access_token: this.authToken };
    this.clear();
    return this.userService.logout(payload);
  }
}
